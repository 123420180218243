import '@/quasar'
export default {
  data() {
    return {

    }
  },
  methods: {
    notifyPositive(msg) {
      this.$q.notify({
        type: "positive",
        message: msg
      })
    },
    notifyNegative(msg) {
      this.$q.notify({
        type: "negative",
        message: msg
      })
    },
    copyObj(obj) {
      return JSON.parse(JSON.stringify(obj))
    }
  }
}